import ActionType from './globalActionType'

//  === KEYWORD
// wtchttype = Waiting open chat preview type
// wtchtdt = Waiting open chat preview Data
// wtchtnm = Waiting open chat preview Product Name
// tpdvc = Type screen device, mbl(mobile) / dkstp (dekstop) 
// dscspg = In Open Discuss Page
// opnctmbl = open chat mobile
// lsdscss = list discuss
// nwctgrp = new chat group
// sndnwctgrp = send new message group to emit
// sndnwctgrplp = send new message lp to emit
// isafltr = is affiliator
// islp = Landing Page status activation
// opndscslp = Open Discuss LP 
// nwctlp = new chat LP
// srchcht = search chat by username
// otpbnk = OTP Bank
// otppn = OTP Pin
// isfreeuplp = is free upgrade LP
// isedtrlp = is editor LP account
// isSubmission = is Submission debt
// memberName = member's name
// rnngtxt = running text
// dscspglp = In Open Discuss LP Page
// lsdscsslp = list discuss LP
// profilerefresh = Profile Refresh
// urlFb = urlFacebook


const globalState = {
  totalOrder: 0,
  isLogin: undefined,
  token: undefined,
  chatToken: undefined,
  idMember: undefined,
  username: undefined,
  following: undefined,
  readyChat: undefined,
  wtchttype: undefined,
  wtchtdt: undefined,
  wtchtnm: undefined,
  listusrcht: undefined,
  tpdvc: 'dkstp',
  opnctmbl: undefined,
  dscspg: undefined,
  lsdscss: undefined,
  nwctgrp: undefined,
  sndnwctgrp: undefined,
  sndnwctgrplp: undefined,
  unrdmsg: 0,
  unrdmsgs: [],
  isafltr: undefined,
  islp: undefined,
  totalOrderSuccess: undefined,
  opndscslp: undefined,
  nwctlp: undefined,
  orgnusrnm: undefined,
  srchcht: undefined,
  otpbnk: undefined,
  otppn: undefined,
  isfreeuplp: undefined,
  isedtrlp: undefined,
  isSubmission: undefined,
  dscspg2: undefined,
  lsdscss2: undefined,
  sndnwctgrp2: undefined,
  memberName: undefined,
  rnngtxt: undefined,
  dscspglp: undefined,
  lsdscsslp: undefined,
  profilerefresh: true,
  fbUrl: undefined,
  fbAccount: undefined,
  iscslp: undefined,
  userrole: undefined,
  account: {
    data: {},
    updated: undefined,
  },
  balance: {
    data: {},
    updated: undefined,
  },
  voucher: {
    data: {},
    updated: undefined,
  },
  statuslp: {
    data: {},
    updated: undefined,
  },
  statusdebt: {
    data: {},
    updated: undefined,
  },
  addrcheck: {
    isaddrexists: undefined,
    hidalerttime: undefined,
    ishidalert: undefined,
  },
  fbcheck: {
    isfbexists: undefined,
    hidalerttime: undefined,
    ishidalert: undefined,
  },
  newmodal: {
    ismembernew: undefined,
    hidmodal: undefined,
  },
  isFirstLogin: undefined,
  massCart: {
    data: [],
  },
  isFeedsPage: false,
  paylater: {
    data: {},
    updated: undefined,
    lastPaymentTimestamp: undefined,
  },
  lastWithdrawal: null,
  broadcastNotif: {
    lastRequest: null,
    data: [],
  },
  // template: {
  //   header: true,
  //   footer: true,
  //   bottomBar: true,
  //   chatModal: true,
  // },
  otpRegisterSupplier: {
    phone: null,
    isVerified: false,
    expiredTime: null,
  },
  chatListType: 'seller', // seller || partner
  cart: {
    common: 0,
    instant: 0,
  }
}

// Reducer
const rootReducer = (state = globalState, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        isLogin: true,
        token: action.payload.token,
        chatToken: action.payload.chatToken,
        username: action.payload.username,
        idMember: action.payload.idMember,
        orgnusrnm: action.payload.originUsername,
        memberName: action.payload.memberName,
        isFirstLogin: action.payload.isFirstLogin,
      }

    case ActionType.FOLLOWING:
      return {
        ...state,
        following: action.payload.data
      }

    case ActionType.LOGOUT:
      return {
        ...globalState,
      }

    case ActionType.ADD_ORDER:
      return {
        ...state,
        totalOrder: state.totalOrder + action.payload.quantity
      }

    case ActionType.MINUS_ORDER:
      return {
        ...state,
        totalOrder: action.payload.quantity
      }

    case ActionType.TOTAL_ORDER:
      return {
        ...state,
        totalOrder: action.payload.quantity
      }

    case ActionType.RESET_ORDER:
      return {
        ...state,
        totalOrder: 0
      }

    case ActionType.RESET_UNREAD_MESSAGE:
      return {
        ...state,
        unrdmsg: 0
      }

    case ActionType.READYCHAT:
      return {
        ...state,
        readyChat: action.payload.ready
      }

    case ActionType.IN_DISCUSS:
      return {
        ...state,
        dscspg: action.payload.in_discuss
      }

    case ActionType.SET_PREVIEW_CHAT_ORDER:
      return {
        ...state,
        wtchttype: action.payload.type,
        wtchtdt: action.payload.id,
      }

    case ActionType.SET_PREVIEW_CHAT_PRODUCT:
      return {
        ...state,
        wtchttype: action.payload.type,
        wtchtdt: action.payload.id,
        wtchtnm: action.payload.name
      }

    case ActionType.RESET_PREVIEW_CHAT_ORDER:
      return {
        ...state,
        wtchttype: undefined,
        wtchtdt: undefined,
        wtchtnm: undefined
      }

    case ActionType.SET_DEVICE:
      return {
        ...state,
        tpdvc: action.payload.type,
      }

    case ActionType.OPEN_CHAT_MBL:
      return {
        ...state,
        opnctmbl: action.payload.open,
      }

    case ActionType.LIST_DISCUSS:
      return {
        ...state,
        lsdscss: action.payload.list,
      }

    case ActionType.NEW_CHAT_GROUP:
      return {
        ...state,
        nwctgrp: action.payload.message,
      }

    case ActionType.NEW_CHAT_LP:
      return {
        ...state,
        nwctlp: action.payload.message,
      }

    case ActionType.EMIT_NEW_CHAT_GROUP:
      return {
        ...state,
        sndnwctgrp: action.payload.message,
      }

    case ActionType.EMIT_NEW_CHAT_LP:
      return {
        ...state,
        sndnwctgrplp: action.payload.message,
      }

    case ActionType.IS_AFFILIATOR:
      return {
        ...state,
        isafltr: action.payload.isafltr,
      }

    case ActionType.IS_LP:
      return {
        ...state,
        islp: action.payload.islp,
      }

    case ActionType.TOTAL_ORDER_SUCCESS:
      return {
        ...state,
        totalOrderSuccess: action.payload.totalOrderSuccess,
      }

    case ActionType.OPEN_DISCUSS_LP:
      return {
        ...state,
        opndscslp: action.payload.id,
      }

    case ActionType.SEARCH_CHAT:
      return {
        ...state,
        srchcht: action.payload.username,
      }

    case ActionType.OTP_BANK:
      return {
        ...state,
        otpbnk: action.payload.otpbnk,
      }

    case ActionType.OTP_PIN:
      return {
        ...state,
        otppn: action.payload.otppn,
      }

    case ActionType.IS_FREE_UPGRADE_LP:
      return {
        ...state,
        isfreeuplp: action.payload.isfreeuplp,
      }

    case ActionType.IS_EDITOR_LP:
      return {
        ...state,
        isedtrlp: action.payload.isedtrlp,
      }

    case ActionType.RUNNING_TEXT:
      return {
        ...state,
        rnngtxt: action.payload.rnngtxt,
      }

    case ActionType.SET_IS_SUBMISSION:
      return {
        ...state,
        isSubmission: action.payload.isSubmission,
      }

    case ActionType.IN_DISCUSS2:
      return {
        ...state,
        dscspg: action.payload.in_discuss2
      }

    case ActionType.LIST_DISCUSS2:
      return {
        ...state,
        lsdscss2: action.payload.list2,
      }

    case ActionType.EMIT_NEW_CHAT_GROUP2:
      return {
        ...state,
        sndnwctgrp2: action.payload.message2,
      }

    case ActionType.IN_DISCUSS_LP:
      return {
        ...state,
        dscspglp: action.payload.in_discuss_lp
      }

    case ActionType.LIST_DISCUSS_LP:
      return {
        ...state,
        lsdscsslp: action.payload.list_lp,
      }

    case ActionType.PROFILE_REFRESH:
      return {
        ...state,
        profilerefresh: action.payload.profilerefresh,
      }

    case ActionType.FACEBOOK_ACCOUNT:
      return {
        ...state,
        fbUrl: action.payload.fbUrl,
        fbAccount: action.payload.fbAccount,
      }

    case ActionType.IS_CS_LP:
      return {
        ...state,
        iscslp: action.payload.iscslp,
      }

    case ActionType.USER_ROLE:
      return {
        ...state,
        userrole: action.payload.userrole,
      }

    case ActionType.SET_UNREAD_MESSAGES:
      return {
        ...state,
        unrdmsgs: action.payload,
      }

    case ActionType.RESET_UNREAD_MESSAGES:
      return {
        ...state,
        unrdmsgs: [],
      }

    case ActionType.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      }

    case ActionType.SET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      }

    case ActionType.SET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
      }

    case ActionType.SET_STATUS_LP:
      return {
        ...state,
        statuslp: action.payload,
      }

    case ActionType.SET_STATUS_DEBT:
      return {
        ...state,
        statusdebt: action.payload,
      }

    case ActionType.SET_ADDR_CHECK:
      return {
        ...state,
        addrcheck: action.payload,
      }

    case ActionType.SET_FB_CHECK:
      return {
        ...state,
        fbcheck: action.payload,
      }

    case ActionType.SET_NEW_MEMBER_MODAL:
      return {
        ...state,
        newmodal: action.payload,
      }

    case ActionType.SET_MASS_CART_DATA:
      return {
        ...state,
        massCart: {
          ...state.masCart,
          data: action.payload,
        }
      }

    case ActionType.SET_IS_FEEDS_PAGE:
      return {
        ...state,
        isFeedsPage: action.payload,
      }

    case ActionType.SET_PAYLATER:
      return {
        ...state,
        paylater: action.payload,
      }

    case ActionType.SET_LAST_WITHDRAWAL:
      return {
        ...state,
        lastWithdrawal: action.payload,
      }

    case ActionType.SET_BROADCAST_NOTIFICATION:
      return {
        ...state,
        broadcastNotif: action.payload,
      }

    case ActionType.OTP_REGISTER_SUPPLIER:
      return {
        ...state,
        otpRegisterSupplier: action.payload,
      }

    case ActionType.SET_CHAT_LIST_TYPE:
      return {
        ...state,
        chatListType: action.payload,
      }

    default:
      return state
  }
}

export default rootReducer;
